import React from "react";
import classes from "./CertificationTypes.module.css";
import { t } from "i18next";
export default function CertificationTypeCard({ card }) {
  return (
    <div key="card-container" className={classes.cardContainer}>
      <div key="photo" className={classes.photo}>
        <img
          key="cover"
          src={card.cover}
          alt={card.title}
          className={classes.photo}
        />
      </div>
      <div key="content-container" className={classes.cardContent}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "8px",
          }}
        >
          <p key="title" className={classes.cardTitle}>
            {card.title}{" "}
          </p>
          <p key="subtitle" className={classes.cardDescription}>
            {t("trademarkPage.general.typesOf.suchAs")}
          </p>
          <div
            key="categories-container"
            className={classes.categoriesContainer}
          >
            {card.categories
              .filter((category) =>
                category?.countries?.includes(process.env.REACT_APP_COUNTRY)
              )
              .map((category) => (
                <div key="category_index" className={classes.cardCategoryRow}>
                  <img src={category.icon} style={{ width: 15, height: 15 }} />
                  <p className={classes.cardCategoryText}>{category.title}</p>
                </div>
              ))}
          </div>
        </div>
        <button key="button" className={classes.button} onClick={card.onPress}>
          {t("trademarkPage.general.typesOf.learn")}
        </button>
      </div>
    </div>
  );
}
