import all_icon from "../assets/icons/all_icon.svg";
import benefit_gift from "../assets/icons/benefit_gift.svg";
import clock_grey from "../assets/icons/clock_grey.svg";
import close_x from "../assets/icons/close_x.svg";
import close_x_circle_white from "../assets/icons/close_x_circle_white.svg";
import close_x_white from "../assets/icons/close_x_white.svg";
import distance from "../assets/icons/distance.svg";
import edit_button from "../assets/icons/edit_button.svg";
import facebook_green from "../assets/icons/facebook_green.svg";
import flag_green from "../assets/icons/flag_green.svg";
import form from "../assets/icons/form.svg";
import green_arrow_down from "../assets/icons/green_arrow_down.svg";
import grey_arrow_down from "../assets/icons/grey_arrow_down.svg";
import grey_tag from "../assets/icons/grey_tag.svg";
import hamburger_menu from "../assets/icons/hamburger_menu.svg";
import happy_face_green from "../assets/icons/happy_face_green.svg";
import information_grey from "../assets/icons/information_grey.svg";
import instagram_green from "../assets/icons/instagram_green.svg";
import instagram_icon from "../assets/icons/instagram_icon.svg";
import like_grey from "../assets/icons/like_grey.svg";
import linkedin_green from "../assets/icons/linkedin_green.svg";
import location_button from "../assets/icons/location_button.svg";
import location_green from "../assets/icons/location_green.svg";
import location_grey from "../assets/icons/location_grey.svg";
import location_white from "../assets/icons/location_white.svg";
import map from "../assets/icons/map.svg";
import news_left_button from "../assets/icons/news_left_button.svg";
import news_right_button from "../assets/icons/news_right_button.svg";
import pencil_white from "../assets/icons/pencil_white.svg";
import phone_grey from "../assets/icons/phone_grey.svg";
import pink_heart from "../assets/icons/pink_heart.svg";
import print_button from "../assets/icons/print_button.svg";
import recent_search from "../assets/icons/recent_search.svg";
import reservation from "../assets/icons/reservation.svg";
import review_icon from "../assets/icons/review_icon.svg";
import search_bar_icon from "../assets/icons/search_bar_icon.svg";
import search_button_icon from "../assets/icons/search_button_icon.svg";
import search_icon from "../assets/icons/search_icon.svg";
import share_icon from "../assets/icons/share_icon.svg";
import thumbs_up_empty from "../assets/icons/thumbs_up_empty.svg";
import tiktok_green from "../assets/icons/tiktok_green.svg";
import whatsapp_green from "../assets/icons/whatsapp_green.svg";
import white_arrow_down from "../assets/icons/white_arrow_down.svg";
import white_arrow_left from "../assets/icons/white_arrow_left.svg";
import white_arrow_right from "../assets/icons/white_arrow_right.svg";
import white_heart_empty from "../assets/icons/white_heart_empty.svg";
import white_heart_full from "../assets/icons/white_heart_full.svg";
import white_location from "../assets/icons/white_location.svg";

import face_excellent from "../assets/icons/rating/excellent.svg";
import face_good from "../assets/icons/rating/good.svg";
import face_mah from "../assets/icons/rating/mah.svg";
import face_poor from "../assets/icons/rating/poor.svg";
import face_very_good from "../assets/icons/rating/very_good.svg";

import face_excellent_green from "../assets/icons/greenRating/excellent.svg";
import face_good_green from "../assets/icons/greenRating/good.svg";
import face_mah_green from "../assets/icons/greenRating/mah.svg";
import face_poor_green from "../assets/icons/greenRating/poor.svg";
import face_very_good_green from "../assets/icons/greenRating/very_good.svg";

import benefit from "../assets/icons/tagIcons/greyTags/benefit.svg";
import business_benefits from "../assets/icons/tagIcons/greyTags/business_benefits.svg";
import certified from "../assets/icons/tagIcons/greyTags/certified.svg";
import delivery from "../assets/icons/tagIcons/greyTags/delivery.svg";
import gluten_free from "../assets/icons/tagIcons/greyTags/gluten_free.svg";
import kosher from "../assets/icons/tagIcons/greyTags/kosher.svg";
import open_now from "../assets/icons/tagIcons/greyTags/open_now.svg";
import restaurant_benefits from "../assets/icons/tagIcons/greyTags/restaurant_benefits.svg";
import vegan from "../assets/icons/tagIcons/greyTags/vegan.svg";
import vegan_options from "../assets/icons/tagIcons/greyTags/vegan_options.svg";

import icon_leaf from "../assets/icons/icon_leaf.svg";
import recipe_difficulty from "../assets/icons/recipe_difficulty.svg";
import recipe_duration from "../assets/icons/recipe_duration.svg";
import recipe_servings from "../assets/icons/recipe_servings.svg";

import benefits_entity from "../assets/icons/entityIcons/entities_green/benefits.svg";
import blogs_entity from "../assets/icons/entityIcons/entities_green/blogs.svg";
import businesses_entity from "../assets/icons/entityIcons/entities_green/businesses.svg";
import employers_entity from "../assets/icons/entityIcons/entities_green/employers.svg";
import products_entity from "../assets/icons/entityIcons/entities_green/products.svg";
import recipes_entity from "../assets/icons/entityIcons/entities_green/recipes.svg";
import restaurants_entity from "../assets/icons/entityIcons/entities_green/restaurants.svg";

import about_us_button from "../assets/icons/getInvolved/about_us.svg";
import benefits_button from "../assets/icons/getInvolved/benefits.svg";
import blogs_button from "../assets/icons/getInvolved/blogs.svg";
import businesses_button from "../assets/icons/getInvolved/businesses.svg";
import careers_button from "../assets/icons/getInvolved/careers.svg";
import companies_button from "../assets/icons/getInvolved/companies.svg";
import partners_button from "../assets/icons/getInvolved/partners.png";
import contact_us_button from "../assets/icons/getInvolved/contact_us.svg";
import donate_button from "../assets/icons/getInvolved/donate.svg";
import download_app from "../assets/icons/getInvolved/download_app.svg";
import employers_button from "../assets/icons/getInvolved/employers.svg";
import get_certified_button from "../assets/icons/getInvolved/get_certified.svg";
import information_icon from "../assets/icons/getInvolved/information_icon_no_text.svg";
import join_va_button from "../assets/icons/getInvolved/join_va.svg";
import newsletter_button from "../assets/icons/getInvolved/newsletter.svg";
import recipes_button from "../assets/icons/getInvolved/recipes.svg";
import restaurants_button from "../assets/icons/getInvolved/restaurants.svg";
import trademark_button from "../assets/icons/getInvolved/trademark.svg";
import volunteer_button from "../assets/icons/getInvolved/volunteer.svg";

import bug from "../assets/icons/contact_us_options/bug.svg";
import new_business from "../assets/icons/contact_us_options/new_business.svg";
import other from "../assets/icons/contact_us_options/other.svg";
import update_business from "../assets/icons/contact_us_options/update_business.svg";
import vactive from "../assets/icons/contact_us_options/vactive.svg";
import volunteer from "../assets/icons/contact_us_options/volunteer.svg";

import phone from "../assets/icons/trademark/phone.png";
import list from "../assets/icons/trademark/list.png";
import trophy from "../assets/icons/trademark/trophy.png";

import cafe_icon from "../assets/icons/trademarks/types/food/cafe.png";
import bread_icon from "../assets/icons/trademarks/types/food/bread.png";
import ice_icon from "../assets/icons/trademarks/types/food/ice.png";
import pizza_icon from "../assets/icons/trademarks/types/food/pizza.png";

import bed_icon from "../assets/icons/trademarks/types/business/bed.png";
import gift_icon from "../assets/icons/trademarks/types/business/gift.png";
import kolav_icon from "../assets/icons/trademarks/types/business/kolav.png";
import serve_icon from "../assets/icons/trademarks/types/business/serve.png";

import cosmetic_icon from "../assets/icons/trademarks/types/product/cosmetic.png";
import kj_icon from "../assets/icons/trademarks/types/product/kj.png";
import restaurant_icon from "../assets/icons/trademarks/types/product/restaurant.png";
import pills from "../assets/icons/trademarks/types/product/pills.png";

export default {
  trademark:{
    food:{
      cafe: cafe_icon,
      bread: bread_icon,
      ice: ice_icon,
      pizza: pizza_icon,
    },
    business:{
      bed: bed_icon,
      gift: gift_icon,
      kolav: kolav_icon,
      serve: serve_icon,
     
    },
    product:{
      cosmetic: cosmetic_icon,
      kj: kj_icon,
      restaurant: restaurant_icon,
      pills: pills,
    }
  },
  map: map,
  form: form,
  pencilWhite: pencil_white,
  reviewIcon: review_icon,
  printButton: print_button,
  shareIcon: share_icon,
  pinkHeart: pink_heart,
  whiteArrowRight: white_arrow_right,
  whiteArrowLeft: white_arrow_left,
  whiteHeartFull: white_heart_full,
  whiteHeartEmpty: white_heart_empty,
  instagramIcon: instagram_icon,
  instagramGreen: instagram_green,
  whatsappGreen: whatsapp_green,
  linkedinGreen: linkedin_green,
  facebookGreen: facebook_green,
  tiktokGreen: tiktok_green,
  clockGrey: clock_grey,
  likeGrey: like_grey,
  locationGrey: location_grey,
  locationGreen: location_green,
  locationWhite: location_white,
  locationButton: location_button,
  phoneGrey: phone_grey,
  reservation: reservation,
  benefitGift: benefit_gift,
  informationGrey: information_grey,
  thumbsUpEmpty: thumbs_up_empty,
  happyFaceGreen: happy_face_green,
  faceExcellent: face_excellent,
  faceGood: face_good,
  faceMah: face_mah,
  facePoor: face_poor,
  faceVeryGood: face_very_good,
  faceExcellentGreen: face_excellent_green,
  faceGoodGreen: face_good_green,
  faceMahGreen: face_mah_green,
  facePoorGreen: face_poor_green,
  faceVeryGoodGreen: face_very_good_green,
  editButton: edit_button,
  whiteLocation: white_location,
  greenFlag: flag_green,
  newsRightButton: news_right_button,
  newsLeftButton: news_left_button,
  searchButtonIcon: search_button_icon,
  distance: distance,
  hamburgerMenu: hamburger_menu,
  benefitTag: benefit,
  openNowTag: open_now,
  veganTag: vegan,
  deliveryTag: delivery,
  kosherTag: kosher,
  certifiedTag: certified,
  glutenFreeTag: gluten_free,
  veganOptionsTags: vegan_options,
  restaurantBenefits: restaurant_benefits,
  businessBenefits: business_benefits,
  searchIcon: search_icon,
  searchBarIcon: search_bar_icon,
  recentSearch: recent_search,
  allIcon: all_icon,
  closeX: close_x,
  closeXWhite: close_x_white,
  closeXCircleWhite: close_x_circle_white,
  greenArrowDown: green_arrow_down,
  greyArrowDown: grey_arrow_down,
  whiteArrowDown: white_arrow_down,
  recipePreparation: recipe_difficulty,
  recipeDuration: recipe_duration,
  recipeServing: recipe_servings,
  iconLeaf: icon_leaf,
  restaurantsEntity: restaurants_entity,
  businessesEntity: businesses_entity,
  productsEntity: products_entity,
  benefitsEntity: benefits_entity,
  recipesEntity: recipes_entity,
  blogsEntity: blogs_entity,
  employersEntity: employers_entity,
  restaurantsButton: restaurants_button,
  businessesButton: businesses_button,
  recipesButton: recipes_button,
  employersButton: employers_button,
  benefitsButton: benefits_button,
  companiesButton: companies_button,
  partnersButton: partners_button,
  blogsButton: blogs_button,
  careersButton: careers_button,
  criteriaButton: information_icon,
  contactUsButton: contact_us_button,
  aboutUsButton: about_us_button,
  trademarkButton: trademark_button,
  donateButton: donate_button,
  getCertifiedButton: get_certified_button,
  joinVaButton: join_va_button,
  newsletterButton: newsletter_button,
  volunteerButton: volunteer_button,
  downloadApp: download_app,
  contactVolunteer: volunteer,
  contactNewBusiness: new_business,
  contactUpdateBusiness: update_business,
  contactVactive: vactive,
  contactBug: bug,
  contactOther: other,
  greyTag: grey_tag,
  phone: phone,
  list: list,
  trophy: trophy,
};

export const iconsAlts = {
  map: "map",
  form: "form",
  pencilWhite: "white pencil",
  reviewIcon: "red talk bubble with 2 dots inside",
  printButton: "printer icon",
  shareIcon: "green arrow pointing to the right",
  pinkHeart: "pink heart",
  whiteArrowRight: "white arrow pointing to the right",
  whiteArrowLeft: "white arrow pointing to the left",
  whiteHeartFull: "white heart",
  whiteHeartEmpty: "white heart",
  instagramIcon: "instagram icon",
  instagramGreen: "green instagram icon",
  whatsappGreen: "green whatsapp icon",
  linkedinGreen: "green linkedin icon",
  facebookGreen: "green facebook icon",
  tiktokGreen: "green tiktok icon",
  clockGrey: "grey clock icon",
  likeGrey: "grey like icon",
  locationGrey: "grey location icon",
  locationGreen: "green location icon",
  locationWhite: "white location icon",
  locationButton: "green location icon",
  phoneGrey: "grey phone icon",
  reservation: "small calendar icon",
  benefitGift: "small red gift icon",
  informationGrey: "grey information icon",
  thumbsUpEmpty: "thumbs up icon",
  happyFaceGreen: "green happy face",
  faceExcellent: "very happy grey face",
  faceGood: "happy grey face",
  faceMah: "neutral grey face",
  facePoor: "sad grey face",
  faceVeryGood: "very sad grey face",
  faceExcellentGreen: "very happy green face",
  faceGoodGreen: "happy green face",
  faceMahGreen: "neutral green face",
  facePoorGreen: "sad green face",
  faceVeryGoodGreen: "very sad green face",
  editButton: "green pencil icon",
  whiteLocation: "white location icon",
  greenFlag: "green flag",
  newsRightButton: "right arrow",
  newsLeftButton: "left arrow",
  searchButtonIcon: "magnifying glass icon",
  distance: "distance icon",
  hamburgerMenu: "3 horizontal lines",
  benefitTag: "grey present icon",
  openNowTag: "small clock icon",
  veganTag: "circle with v inside it",
  deliveryTag: "delivery bike icon",
  kosherTag: "circle with k inside it",
  certifiedTag: "vegan friendly certified logo",
  glutenFreeTag: "gluten free icon",
  veganOptionsTags: "small leaf icon",
  restaurantBenefits: "small dish icon",
  businessBenefits: "small suitcase icon",
  searchIcon: "magnifying glass icon",
  searchBarIcon: "magnifying glass icon",
  recentSearch: "magnifying glass icon",
  allIcon: 'icon indicating "all" ',
  closeX: "x",
  closeXWhite: "white x",
  closeXCircleWhite: "white x",
  greenArrowDown: "green arrow pointing down",
  greyArrowDown: "grey arrow pointing down",
  whiteArrowDown: "white arrow pointing down",
  recipePreparation: "chef hat",
  recipeDuration: "clock icon",
  recipeServing: "dish icon",
  iconLeaf: "leaf icon",
  restaurantsEntity: "cutlery",
  businessesEntity: "shopping cart",
  criteriaButton: "information about certification",
  productsEntity: "grocery bag",
  benefitsEntity: "present",
  recipesEntity: "dish",
  restaurantsButton: "restaurants button",
  businessesButton: "businesses button",
  recipesButton: "recipes button",
  benefitsButton: "benefits button",
  companiesButton: "companies button",
  blogsButton: "blogs button",
  careersButton: "blue suitcase",
  contactUsButton: "talk bubble",
  aboutUsButton: "vegan friendly icon",
  trademarkButton: "vegan friendly icon",
  certifiedPartnersButton: "vegan friendly icon",
  donateButton: "credit card",
  getCertifiedButton: "star badge with a check mark inside it",
  joinVaButton: "pink present icon",
  newsletterButton: "envelope",
  volunteerButton: "form",
  downloadApp: "app",
  contactVolunteer: "small selection of fruit icons",
  contactNewBusiness: "orange suitcase",
  contactUpdateBusiness: "purple notepad with pencil writing on it",
  contactVactive: "cute avocado",
  contactBug: "spider icon",
  contactOther: "small robot icon",
  greyTag: "grey tag icon",
};
