import api from "../api";

export const SET_CERTIFIED_PARTNERS = "SET_CERTIFIED_PARTNERS";

export const getCertifiedPartners = async (dispatch) => {
  try {
    const response = await api.getCertifiedPartners();

    if (response?.success) {
      const tagsFlat = response.data.flatMap((i) => i.tags);
      const allTags = [...new Set(tagsFlat)].sort();
      const bizTypes = response.data.map((i) => i.biz_type);
      const allBizTypes = [...new Set(bizTypes)].sort();

      dispatch({
        type: SET_CERTIFIED_PARTNERS,
        payload: { ...response, allTags, allBizTypes },
      });
    } else {
      dispatch({
        type: SET_CERTIFIED_PARTNERS,
        payload: {
          data: null,
          success: false,
        },
      });
    }
  } catch (error) {
    console.error("getCertifiedPartners error:", error);
    dispatch({
      type: SET_CERTIFIED_PARTNERS,
      payload: {
        data: null,
        success: false,
      },
    });
  }
};
