import React from "react";
import styles from "./HeadingText.module.css";
import { t } from "i18next";

function HeadingText({type}) {
  return (
    <h1 className={styles.headingText}>{t(`trademarkPage.${type}.section5.title`)} </h1>
  );
}

export default HeadingText;
