import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router";
import images, { imagesAlts } from "../../../../../resources/images";
import classes from "./Banner.module.css";

const Banner = ({ scrollToSection }) => {
  const navigate = useNavigate();

  const onBannerClick = () => {
    scrollToSection("certificationRef");
  };

  return (
    <button
      type="button"
      className={classes.mainContainer}
      onClick={onBannerClick}
    >
      <img
        className={classes.icon}
        src={images.bannerSuitcase}
        alt={imagesAlts.bannerSuitcase}
      />
      <div
        className={classes.contentContainer}
        style={{ gap: "20px", paddingTop: "10px", paddingBottom: "10px" }}
      >
        <div className={classes.row}>
          <h4 className={classes.title} style={{ width: "90%" }}>
            {t("trademarkPage.general.banner.title")}
          </h4>
        </div>
        <h4 className={classes.subtitle}>
          {t("trademarkPage.general.banner.cta")}
        </h4>
      </div>
    </button>
  );
};

export default Banner;
