import * as React from "react";
import styles from "./VeganTrademarkInfo.module.css";
import images from "../../../../resources/images";
import { t } from "i18next";
export default function VeganTrademarkInfo({ type }) {
  return (
    <div className={styles.container}>
      <div className={styles.infoCard}>
        <div className={styles.contentWrapper}>
          <div className={styles.headerSection}>
            <div className={styles.imageColumn}>
              <img
                loading="lazy"
                src={images.emptyVfIcon}
                className={styles.trademarkLogo}
                alt="Vegan Friendly Trademark Logo"
              />
            </div>
            <div className={styles.titleColumn}>
              <h1 className={styles.title}>
                {t(`trademarkPage.${type}.section2.title`)}
              </h1>
            </div>
          </div>
        </div>
        <p className={styles.description}>
        {t(`trademarkPage.${type}.section2.subtitle`)}
        </p>
      </div>
    </div>
  );
}
