import React from "react";
import { showcasesData } from "../OfferComponents/ShowcasesData";
import styles from "./OfferPage.module.css";
import PromotionCard from "../../promotionCard/PromotionCard";
import { t } from "i18next";

function OfferPage({type}) {
  return (
    <section className={styles.main}>
      <div className={styles.content}>
      <h1 className={styles.header}>{t(`trademarkPage.${type}.section6.title`)}</h1>
      <div className={styles.items}>
        {showcasesData.map(({ imgSrc, altText, title, description }, index) => (
          <PromotionCard
          key={index}
          imgSrc={imgSrc}
          altText={altText}
          title={title}
          description={description}
          index={index}
          type={type}
          />
        ))}
      </div>
        </div>
    </section>
  );
}

export default OfferPage;
