import { createSearchParams, json } from "react-router-dom";
import config from "../config";

const getURLSByCountry = () => {
  switch (config.currentCountry) {
    case "IL": {
      return {
        facebook: "https://www.facebook.com/veganfriendlyil",
        instagram: "https://www.instagram.com/vegan_friendly_il/",
        linkedin: "https://www.linkedin.com/company/vegan-friendly/mycompany/",
        tiktok: "https://www.tiktok.com/@vegan_friendly_il",
        vegan_active: "https://active.vegan-friendly.co.il/subscribe/",
        vegan_active_homepage:
          "https://active.vegan-friendly.co.il/join-the-revolution",
        careers: "https://chat.whatsapp.com/Fpy8UqJ7SF9AWURteMZ0tW",
        get_certified:
          "https://forms.monday.com/forms/6c59aa8bf4c4e22715b94dd76e64ea71?r=use1",
        donate: "https://active.vegan-friendly.co.il/subscribe/?one_time=true",
        get_product_certified:
          "https://forms.monday.com/forms/f938a08c8810db5e485b7ad4ac31489c?r=use1",
        download_app: "https://getapp.vegan-friendly.com/",
        termsOfUse: "https://business.vegan-friendly.com/app-terms-of-use/",
        privacyPolicy:
          "https://business.vegan-friendly.com/app-privacy-policy/",
      };
    }
    case "US": {
      return {
        facebook: "https://www.facebook.com/veganfriendlyus?mibextid=LQQJ4d",
        instagram: "https://www.instagram.com/vegan_friendly",
        linkedin: "https://www.linkedin.com/company/vegan-friendly",
        tiktok: "https://www.tiktok.com/@vegan_friendly",
        careers: "https://www.linkedin.com/company/vegan-friendly/jobs",
        get_certified:
          "https://forms.monday.com/forms/ddbb6591ba20990414617351d453fbb9?r=use1",
        donate: "https://buy.stripe.com/7sI6s94JBelX64MdQU",
        download_app: "https://getapp.vegan-friendly.com/",
        termsOfUse: "../../../pdf/website_terms_of_use.pdf",
        privacyPolicy: "../../../pdf/website_privacy_policy.pdf",
      };
    }
    case "GB":
    default: {
      return {
        facebook: "https://www.facebook.com/weareveganfriendlyuk",
        instagram: "https://www.instagram.com/vegan_friendly_uk/",
        linkedin: "https://www.linkedin.com/company/vegan-friendly",
        tiktok: "https://www.tiktok.com/@vegan_friendly_uk",
        vegan_active: "",
        careers: "https://www.linkedin.com/company/vegan-friendly/jobs",
        get_certified:
          "https://forms.monday.com/forms/801a7ebde793aae4622fcdc7f95b0f0f?r=use1",
        donate: "",
        download_app: "https://getapp.vegan-friendly.com/",
        termsOfUse: "https://business.vegan-friendly.com/app-terms-of-use/",
        privacyPolicy:
          "https://business.vegan-friendly.com/app-privacy-policy/",
      };
    }
  }
};

export const URLS = getURLSByCountry();

export const linkToInternalPage = (url) => {
  window.open(url);
};

export const linkToExternalPage = (url) => {
  window.open(url, "_blank");
};

export const linkToMainPage = () => {
  return `../`;
};

const formatEntityName = (name) => {
  return name
    .toLowerCase()
    .replaceAll(" + ", " ")
    .replaceAll("+", " ")
    .replaceAll(" - ", " ")
    .replaceAll("-", " ")
    .replaceAll(" \\ ", "-")
    .replaceAll("\\", "-")
    .replaceAll("/", "-")
    .replaceAll(" & ", "&")
    .replaceAll("&", "-and-")
    .replaceAll("'", "")
    .replaceAll("’", "")
    .replaceAll(".", "")
    .replaceAll(" ", "-");
};

export const linkToRestaurant = (restaurantId, entityName) => {
  const name = formatEntityName(entityName);
  return `../restaurant/${name}/${restaurantId}`;
};

export const linkToBusiness = (branchId, entityName) => {
  const name = formatEntityName(entityName);
  return `../branch/${name}/${branchId}`;
};

export const linkToRecipe = (recipeId, entityName) => {
  const name = formatEntityName(entityName);
  return `../recipe/${name}/${recipeId}`;
};

export const linkToCompany = (companyId, entityName) => {
  const name = formatEntityName(entityName);
  return `../brand/${name}/${companyId}`;
};

export const linkToEmployer = (employerId, entityName) => {
  const name = formatEntityName(entityName);
  return `../employer/${name}/${employerId}`;
};

export const linkToBlog = (slug) => {
  return `../blog/${slug}`;
};

export const linkToSearch = (params) => {
  if (params) {
    const linkParams = {};
    if (params.input) {
      linkParams.input = params.input;
    }
    if (params.entityType) {
      linkParams.entity = params.entityType;
    }
    if (params.categoryNames && params.categoryNames.length > 0) {
      linkParams.categories = params.categoryNames.join("-");
    }
    if (params.tagNames && params.tagNames.length > 0) {
      linkParams.tags = params.tagNames.join("-");
    }
    if (params.chainId) {
      linkParams.chainId = params.chainId;
    }
    if (params.businessId) {
      linkParams.businessId = params.businessId;
    }
    if (params.page) {
      linkParams.page = params.page;
    }

    if (params.suggestOverride) {
      linkParams.suggestOverride = params.suggestOverride;
    }

    return `../search?${createSearchParams(linkParams)}`;
  }
  return `../search`;
};

export const linkToVfProjects = () => {
  // return `../vf-projects`;
};

export const linkToAbout = () => {
  return `../about`;
};

export const linkToTrademark = (type = "general", initAtForm = false) => {
  const isUS = config.currentCountry === "US";
  if (isUS) {
    return `../certification/${type}?form=${initAtForm}`;
  }
  return `../trademark/${type}?form=${initAtForm}`;
};

export const linkToCertifiedPartners = (filters = null) => {
  if (filters) {
    return {
      pathname: "../certified-partners",
      search: `?initialBizFilter=${filters.join(",")}`,
    };
  }
  return {
    pathname: "../certified-partners",
    search: `?showBizFilter=true`,
  };
};

export const linkToCriteria = () => {
  return `../criteria-for-certification`;
};

export const linkToAccessibility = () => {
  return `../accessibility`;
};

export const linkToDonate = () => {
  // return `../donate`;
};

export const linkToVolunteer = () => {
  // return `../volunteer`;
};

export const linkToCareers = () => {
  // return `../careers`;
};
