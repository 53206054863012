import React from "react";
import styles from "./CallToAction.module.css";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import * as links from "../../../../utils/links";

function CallToAction({ type }) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (type === "product") {
      navigate(links.linkToCertifiedPartners(["Product"]));
    } else if (type === "business") {
      navigate(links.linkToCertifiedPartners(["Restaurant", "Business"]));
    } else {
      navigate(links.linkToCertifiedPartners());
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      handleClick();
    }
  };

  return (
    <div className={styles.callToAction}>
      <div className={styles.callToActionWrapper}>
        <div
          className={styles.callToActionInner}
          onClick={handleClick}
          onKeyDown={handleKeyPress}
          role="button"
          tabIndex={0}
          aria-label="View full list of vegan friendly companies"
        >
          {t(`trademarkPage.${type}.section5.button`)}
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
