import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: "9px",
  overflow: "hidden",
  boxShadow: "none",

  "&.Mui-expanded": {
    margin: "0px",
    boxShadow: "0px 10px 25px 0px rgba(27, 27, 27, 0.10)",
  },
  // Override the default :first-of-type styles
  "&:first-of-type": {
    borderTopLeftRadius: "9px",
    borderTopRightRadius: "9px",
  },

  // Override the last accordion for consistent bottom border radius
  "&:last-of-type": {
    borderBottomLeftRadius: "9px",
    borderBottomRightRadius: "9px",
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: "rgba(243, 244, 251, 1)", // Default background (closed state)
  // padding: "20px 36px", // Consistent padding
  display: "flex",
  alignItems: "center", // Ensures vertical alignment
  justifyContent: "space-between",
  transition: "background-color 0.3s ease, color 0.3s ease",
  "&.Mui-expanded": {
    backgroundColor: "#FFDFE6", // Pink background for expanded state
    // padding: "20px 36px", // Keeps padding consistent
    minHeight: "49px", // Prevents default Material-UI larger height
  },
  "& .MuiAccordionSummary-content": {
    margin: 0, // Removes extra margin
  },
  "&.Mui-expanded .MuiAccordionSummary-content": {
    margin: 0, // Consistent margin in both states
  },
  "& .MuiTypography-root": {
    fontFamily: "'AlmoniRegular', sans-serif",
    fontSize: "2rem",
    fontWeight: 400,
    lineHeight: "32px",
    color: "var(--color-text, #596475)", // Default text color
    transition: "color 0.3s ease",
  },
  "&.Mui-expanded .MuiTypography-root": {
    color: "var(--color-text, #596475)", // White text color when expanded
  },
  "& .MuiSvgIcon-root": {
    fontSize: "2.5rem", // Increases the icon size (adjust value as needed)
    transition: "font-size 0.3s ease, color 0.3s ease",
  },
  "&.Mui-expanded .MuiSvgIcon-root": {
    fontSize: "2.5rem", // Larger size for expanded state (optional)
    color: "#fff", // Icon color when expanded
  },
}));
const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: "#fff",
  color: "var(--color-text, #596475)",
  fontFamily: "'AlmoniRegular', sans-serif",
  fontSize: "1.7rem",
  fontWeight: 400,
  lineHeight: "25px",
  "& .MuiTypography-root": {
    backgroundColor: "#fff",
    color: "var(--color-text, #596475)",
    fontFamily: "'AlmoniRegular', sans-serif",
    fontSize: "1.7rem",
    fontWeight: 400,
    lineHeight: "25px",
  },
}));

const FaqItem = ({ item, onToggle, isOpen, id }) => {
  const handleChange = () => {
    onToggle(id);
  };

  return (
    <StyledAccordion expanded={isOpen} onChange={handleChange}>
      <StyledAccordionSummary
        expandIcon={
          <ExpandMoreIcon style={{ color: "var(--color-text, #596475)" }} />
        }
      >
        <Typography>{item.title}</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {item.body ? (
          <Typography>{item.body}</Typography>
        ) : (
          <Typography>
            {item.firstBody}{" "}
            <span
              style={{ textDecoration: "underline", color: "#72af35",cursor: "pointer" }}
              onClick={item.onAction}
            >
              {item.action}
            </span>
            {" " + item.secondBody}
          </Typography>
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};
export default FaqItem;
