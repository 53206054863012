// LogoCarousel.js
import React, { useEffect, useRef } from "react";
import useEmblaCarousel from "embla-carousel-react";
import AutoScroll from "embla-carousel-auto-scroll";
import "./embla.css"; // Import the necessary CSS for Embla

const LogoCarousel = ({ logos }) => {
  const autoScrollOptions = { speed: 1 }; // Adjust the speed as needed
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, dragFree: true },
    [AutoScroll(autoScrollOptions)]
  );

  useEffect(() => {
    if (!emblaApi) return;

    const autoScroll = emblaApi.plugins().autoScroll;
    if (!autoScroll) {
      console.error("AutoScroll plugin is not initialized.");
      return;
    }
  }, [emblaApi]);

  return (
    <div className="embla" ref={emblaRef}>
      <div className="embla__container">
        {logos.map((logo, index) => (
          <div className="embla__slide" key={index}>
            <img
              src={logo.src}
              className="embla__slide__img"
              style={{ width: "150px", height: "150px", objectFit: "contain" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoCarousel;
