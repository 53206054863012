import React from "react";
import CertificationTypeCard from "./CertificationTypeCard";
import classes from "./CertificationTypes.module.css";
import { t } from "i18next";
import * as links from "../../../../../utils/links";
import { useNavigate } from "react-router-dom";
import icons from "../../../../../resources/icons";
import images from "../../../../../resources/images";

export default function CertificationTypesContainer({ scrollToSection }) {
  const navigate = useNavigate();
  const cardsData = [
    {
      title: t("trademarkPage.general.typesOf.food.title"),
      cover: images.trademarks.boorekas,

      categories: [
        {
          icon: icons.trademark.food.cafe,
          title: t("trademarkPage.general.typesOf.food.categories.0"),
          countries: ["US", "GB"],

        },
        {
          icon: icons.trademark.food.bread,
          title: t("trademarkPage.general.typesOf.food.categories.1"),
          countries: ["US", "GB"],

        },
        {
          icon: icons.trademark.food.ice,
          title: t("trademarkPage.general.typesOf.food.categories.2"),
          countries: ["US", "GB"],

        },
        {
          icon: icons.trademark.food.pizza,
          title: t("trademarkPage.general.typesOf.food.categories.3"),
          countries: ["US", "GB"],

        },
      ],
      onPress: () => {
        navigate(links.linkToTrademark("business"));
      },
    },
    {
      title: t("trademarkPage.general.typesOf.product.title"),
      cover: images.trademarks.drops,

      categories: [
        {
          icon: icons.trademark.product.restaurant,
          countries: ["US", "GB"],
          title: t("trademarkPage.general.typesOf.product.categories.0"),
        },
        {
          icon: icons.trademark.product.pills,
          countries: ["US", "GB"],
          title: t("trademarkPage.general.typesOf.product.categories.1"),
        },
        {
          icon: icons.trademark.product.cosmetic,
          countries: ["US", "GB"],

          title: t("trademarkPage.general.typesOf.product.categories.2"),
        },
        {
          icon: icons.trademark.product.kj,
          countries: ["US","GB"],

          title: t("trademarkPage.general.typesOf.product.categories.3"),
        },
      ],
      onPress: () => {
        navigate(links.linkToTrademark("product"));
      },
    },
    {
      title: t("trademarkPage.general.typesOf.business.title"),
      cover: images.trademarks.clothing,

      categories: [
        {
          icon: icons.trademark.business.bed,
          countries: ["US", "GB"],

          title: t("trademarkPage.general.typesOf.business.categories.0"),
        },
        {
          icon: icons.trademark.business.serve,
          countries: ["US", "GB"],

          title: t("trademarkPage.general.typesOf.business.categories.1"),
        },
        {
          icon: icons.trademark.business.gift,
          countries: ["US", "GB"],

          title: t("trademarkPage.general.typesOf.business.categories.2"),
        },
        {
          icon: icons.trademark.business.kolav,
          countries: ["GB"],

          title: t("trademarkPage.general.typesOf.business.categories.3"),
        },
      ],
      onPress: () => {
        navigate(links.linkToTrademark("business"));
      },
    },
  ];
  return (
    <div key="containerWrapper" className={classes.containerWrapper}>
      <div key="container" className={classes.container}>
        <p className={classes.mainTitle}>
          {t("trademarkPage.general.typesOf.title")}
        </p>
        <div className={classes.cardsContainer}>
          {cardsData.map((card) => (
            <CertificationTypeCard key={card.title} card={card} />
          ))}
        </div>
        <div key="outro-container" className={classes.outroContainer}>
          <p className={classes.outroText}>
            {t("trademarkPage.general.typesOf.outer") + " "}
            <a
              className={classes.link}
              onClick={() => {
                scrollToSection("certificationRef");
              }}
            >
              {t("trademarkPage.general.typesOf.link")}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
