import { t } from "i18next";
export const showcasesData = [
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/c759f4095b3644919970c2fb0a0256f9/16d7e57050088c45dc7ac946564ceadce49fb259aeed0d04bc376c44e0e9c2cd?apiKey=c759f4095b3644919970c2fb0a0256f9&",
    altText: "Internationally Registered Trademark",
    title: "Internationally Registered Trademark",
    description:
      "Showcase the Vegan Friendly Trademark to grow your vegan consumer base, increase your reach, and make a greater impact for all consumers.",
  },
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/c759f4095b3644919970c2fb0a0256f9/0a6af2fa9b67d2e91963a016c220359e9c7823a8d8ce14349f5956260a447305?apiKey=c759f4095b3644919970c2fb0a0256f9&",
    altText: "Social Media Promotion",
    title: "Social Media Promotion",
    description:
      "Reach 200K+ consumers through our social channels with a welcome post, highlights, story shares, and ongoing promotion. Get featured in our newsletter (15K+ subscribers) and join our monthly giveaways.",
  },
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/c759f4095b3644919970c2fb0a0256f9/bdd8d85230c05319128db5e6b7c57fdbaf71c798227a79aca83bd19bf31c66a0?apiKey=c759f4095b3644919970c2fb0a0256f9&",
    altText: "Digital Presence",
    title: "Digital Presence",
    description:
      "Gain lasting visibility as a highlighted certified partner on our website and app, making your business more accessible than ever.",
  },
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/c759f4095b3644919970c2fb0a0256f9/3b8c05de8b5fbab3250fac4e24b45831e5a0475aa3a6ac9a7def87997cf439d6?apiKey=c759f4095b3644919970c2fb0a0256f9&",
    altText: "Connect with Influencers and Businesses",
    title: "Connect with Influencers and Businesses",
    description:
      "Get access to our Influencer and B2B Catalog to connect with top influencers and like-minded businesses, boosting your reach and visibility.",
  },
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/c759f4095b3644919970c2fb0a0256f9/b1a0c919d49ca040c56c3e1b1106ca388a112e8c93c836da51d18ba1a8c8cb95?apiKey=c759f4095b3644919970c2fb0a0256f9&",
    altText: "Latest Vegan Market Insights",
    title: "Latest Vegan Market Insights",
    description:
      "Discover the latest vegan trends and consumer insights with our in-depth reports, helping you craft marketing strategies that align with today’s consumer preferences.",
  },
];
