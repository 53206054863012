import * as React from "react";
import styles from "./PromotionCard.module.css";
import { t } from "i18next";
export default function PromotionCard({ imgSrc, altText, index, type }) {
  return (
    <div className={styles.promotionWrapper}>
      <div className={styles.iconWrapper}>
        <img
          loading="lazy"
          src={imgSrc}
          className={styles.promotionIcon}
          alt={altText}
        />
      </div>
      <div className={styles.contentWrapper}>
        <h2 className={styles.promotionTitle}>
          {t(`trademarkPage.${type}.section6.items.${index}.title`)}
        </h2>
        <p className={styles.promotionDescription}>
          {t(`trademarkPage.${type}.section6.items.${index}.body`)}
        </p>
      </div>
    </div>
  );
}
