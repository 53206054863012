import classes from "./CompaniesItem.module.css";
import { useNavigate } from "react-router-dom";
import * as navigationLinks from "../../../utils/links";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import images from "../../../resources/images";

const CompaniesItem = ({
  data,
  style,
  isLoading = true,
  isCertifiedPartners = false,
}) => {
  const company = data;
  const navigate = useNavigate();

  const onCompanyClick =
    company.disableClick === true
      ? null
      : () => {
          navigate(navigationLinks.linkToCompany(data.id, data.name));
        };

  return (
    <div
      className={`${style} ${classes.mainContainer} `}
      onClick={onCompanyClick}
    >
      <div className={classes.imageContainer}>
        {isLoading ? (
          <Skeleton
            height="100%"
            style={{
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
            }}
          />
        ) : (
          <img
            className={`${classes.image} ${
              company.disableClick ? classes.noCursor : ""
            }`}
            src={company.logo ? company.logo : images.defaultEntityLogo}
            alt="company logo"
          />
        )}
      </div>
      <div
      style={{ height: isCertifiedPartners ? "15rem" : "12rem" }}
        className={`${classes.contentContainer} ${
          company.disableClick ? classes.noCursor : ""
        }`}
      >
        <p className={classes.companyTitle}>
          {isLoading ? (
            <Skeleton borderRadius="20px" width="80%" />
          ) : (
            company.name
          )}
        </p>
        <p
          className={classes.companyDescription}
          style={{ height: isCertifiedPartners ? "6rem" : "3rem" }}
        >
          {company.description}
        </p>
        {company.subCategories && (
          <div className={classes.tagContainer}>
            {company.subCategories.map((category, index, row) => (
              <p className={classes.tag} key={category.category_name}>
                {category.category_name}
                {index !== row.length - 1 ? "," : ""}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompaniesItem;
