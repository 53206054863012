import * as React from "react";
import styles from "./CriteriaSection.module.css";
import { t } from "i18next";
const getCriteriaData = (type) => ({
  images: [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/c759f4095b3644919970c2fb0a0256f9/fbe44086728901d7a7da6f4c342fa1fd46176350992af1ee52b2e91e80caa062?apiKey=c759f4095b3644919970c2fb0a0256f9&",
      alt: "Vegan Friendly Certification Symbol",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/c759f4095b3644919970c2fb0a0256f9/058e864c8e7b2a821d00142fe3d864a8a64d1e7b277f680d2ab58446cf64fb92?apiKey=c759f4095b3644919970c2fb0a0256f9&",
      alt: "Vegan Product Certification Badge",
    },
  ],
  content: {
    mainTitle: t(`trademarkPage.${type}.section7.first_criteria.title`),
    mainDescription: t(`trademarkPage.${type}.section7.first_criteria.body`),
    secondaryTitle: t(`trademarkPage.${type}.section7.second_criteria.title`),
    secondaryDescription: t(
      `trademarkPage.${type}.section7.second_criteria.body`
    ),
  },
});

const CriteriaSection = React.forwardRef((props, ref) => {
  const contentData = getCriteriaData(props.type);
  return (
    <section
      ref={ref}
      className={styles.mainContainer}
      aria-labelledby="criteria-heading"
    >
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <h1 id="criteria-heading" className={styles.heading}>
            {t(`trademarkPage.${props.type}.section7.title`)}
          </h1>
          <p className={styles.subtitle}>
            {t(`trademarkPage.${props.type}.section7.subtitle`)}
          </p>

          <div className={styles.content}>
            <div className={styles.imageContainer}>
              {contentData.images.map((image, index) => (
                <div className={styles.contentContainer}>
                  <img
                    key={index}
                    loading="lazy"
                    src={image.src}
                    alt={image.alt}
                    className={
                      index === 0 ? styles.image : styles.imageSecondary
                    }
                  />
                  <div className={styles.textContent}>
                    <h2 className={styles.mainTitle}>
                      {index === 0
                        ? contentData.content.mainTitle
                        : contentData.content.secondaryTitle}
                    </h2>
                    <p className={styles.description}>
                      {index === 0
                        ? contentData.content.mainDescription
                        : contentData.content.secondaryDescription}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <p className={styles.footer}>
            {t(`trademarkPage.${props.type}.section7.disclaimer`)}
          </p>
        </div>
      </div>
    </section>
  );
});

export default CriteriaSection;
