import React from "react";
import images from "../../../../resources/images";
import classes from "./CardItem.module.css";
export default function CardItem({ item, index, isMobile }) {
  return (
    <div
      key="cardItem"
      className={classes.cardItemContainer}
      style={{ zIndex: index === 3 ? 0 : 10 }}
    >
      <div
        className={index !== 3 ? classes.upperLip : classes.upperLipLast}
      ></div>
      <div key="content" className={classes.contentContaienr}>
        <div
          key="step"
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {index !== 3 ? (
            <>
              <img
                src={
                  isMobile ? images.flowChartArrowMobile : images.flowChartArrow
                }
                style={{
                  objectFit: "contain",
                  width: "75%",
                  alignSelf: "center",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  textAlign: "center",
                  marginBottom: "-5px",
                }}
              >
                <p className={classes.step}>STEP</p>
                <p className={classes.stepCount}>
                  {(index + 1).toString().padStart(2, "0")}
                </p>
              </div>
            </>
          ) : (
            <img
              src={images.flowChartLast}
              style={{
                objectFit: "contain",
                width: "75%",
                alignSelf: "center",
              }}
            />
          )}
        </div>
        {item.icon && <img src={item.icon} className={classes.icon} />}
        <p className={index === 3 ? classes.titleLast : classes.title}>
          {item.title}
        </p>
        {item.action && (
          <p className={classes.subtitle}>
            {item.first_body}{" "}
            <span onClick={item.onPress} className={classes.action}>
              {item.action}
            </span>{" "}
            {item.second_body}
          </p>
        )}
        <p className={classes.subtitle}>{item.description}</p>
      </div>

      {isMobile && index !== 3 ? (
        <img
          src={images.flowChartDownArrow}
          style={{
            width: "90%",
            marginTop: "-5%",
            position: "relative",
            zIndex: 5,
          }}
        />
      ) : (
        <div
          key="bg"
          className={index !== 3 ? classes.bottomLip : classes.bottomLipLast}
        ></div>
      )}
      {index === 3 && (
        <div
          style={{
            overflow: "hidden",
            position: "absolute",
            right: "-10%",
            bottom: "-25%",
            backgroundColor: "transparent",
            zIndex: 1,
            height: "150%",
          }}
        >
          <img
            src={images.confetti}
            style={{
              maxHeight: "500px",
              objectFit: "contain",
              left: "50%",
              top: "50%",
              transform: "translate(20%, -15%)",
            }}
          />
        </div>
      )}
    </div>
  );
}
