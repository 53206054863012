import classesGreen from "./GreenTag.module.css";
import classesHollow from "./HollowTag.module.css";

const GreenTag = ({
  tag,
  tagName,
  isSelected,
  isDisabled,
  onTagSelected,
  icon,
  iconEnd,
  iconSelected,
  style,
  className,
  isWhite = false,
  isHollow = false,
}) => {
  const classes = isHollow ? classesHollow : classesGreen;
  const containerStyle = isSelected
    ? classes.tagContainerSelected
    : isDisabled
    ? classes.tagContainerDisabled
    : classes.tagContainer;

  const textStyle = isSelected
    ? classes.tagTextSelected
    : isDisabled
    ? classes.tagTextDisabled
    : classes.tagText;

  const iconStyle = isSelected
    ? classes.iconSelected
    : isDisabled
    ? classes.iconDisabled
    : classes.icon;

  return (
    <button
      data-testid="greenTagItem"
      type="button"
      style={style}
      className={`${className} ${containerStyle}`}
      onClick={() => onTagSelected(tag)}
    >
      {icon && <img className={iconStyle} src={icon} />}
      <p className={textStyle}>{tagName}</p>
      {iconEnd && <img className={classes.iconEnd} src={iconEnd} />}
    </button>
  );
};

export default GreenTag;
