import React from "react";
import styles from "./Main.module.css";
import HeadingText from "./HeadingText";
import ImageCarousel from "./LogoCarousel";
import CallToAction from "./CallToAction";
import images from "../../../../resources/images";
function Main({ type }) {
  // const logos = Array.from({ length: 20 }, (_, index) => ({
  //   id: index + 1,
  //   src: `https://picsum.photos/200/200?random=${index + 1}`,
  //   alt: `Placeholder Image ${index + 1}`,
  // }));
  const imagesPerType =
    type === "general"
      ? [...images.trademarks["business"], ...images.trademarks["product"]]
      : images.trademarks[type];
  const logos = imagesPerType.map((logo, index) => ({
    id: index + 1,
    src: logo,
  }));

  return (
    <main className={styles.main}>
      <HeadingText type={type} />
      <ImageCarousel logos={logos} />
      <CallToAction type={type} />
    </main>
  );
}

export default Main;
