import ilFlag from "../assets/images/il.svg";
import ukFlag from "../assets/images/uk.svg";
import usFlag from "../assets/images/us.svg";

import benefitsEntity from "../assets/icons/entityIcons/entities_green/benefits.svg";
import blogsEntity from "../assets/icons/entityIcons/entities_green/blogs.svg";
import businessesEntity from "../assets/icons/entityIcons/entities_green/businesses.svg";
import employersEntity from "../assets/icons/entityIcons/entities_green/employers.svg";
import productsEntity from "../assets/icons/entityIcons/entities_green/products.svg";
import recipesEntity from "../assets/icons/entityIcons/entities_green/recipes.svg";
import restaurantsEntity from "../assets/icons/entityIcons/entities_green/restaurants.svg";

import config from "../config";
import { getCurrentCountry } from "../utils/utilFunctions";

export const ALL = "all";
export const RESTAURANTS = "restaurants";
export const BENEFITS = "benefits";
export const RECIPES = "recipes";
export const BRANCHES = "branches";
export const NEWS = "news";
export const BRANDS = "companies";
export const BLOGS = "blogs";
export const EMPLOYERS = "employers"
export const CERTIFIED_PARTNERS = "certified_partners";

export const VF_ENTITIES_TYPE = [
  {
    name: ALL,
    label: "all",
  },
  {
    name: RESTAURANTS,
    label: "restaurants",
    img: restaurantsEntity,
  },
  {
    name: BENEFITS,
    label: "benefits",
    img: benefitsEntity,
  },
  {
    name: RECIPES,
    label: "recipes",
    img: recipesEntity,
  },
  {
    name: BRANCHES,
    label: "branches",
    img: businessesEntity,
  },
  {
    name: BRANDS,
    label: "companies",
    img: productsEntity,
  },
  {
    name: BLOGS,
    label: "blogs",
    img: blogsEntity
  },
  {
    name: EMPLOYERS,
    label: "employers",
    img: employersEntity
  },
];

export const getSuggestedEntityBySearchTerm = (searchTerm) => {
  for (let key of Object.keys(searchTermKeys)) {
    if (searchTerm.includes(key)) { return searchTermKeys[key]; }
  }
  return null;
}

export const searchTermKeys = {
  "מתכון ל": RECIPES,
  "מתכון": RECIPES,
  "מסעדה": RESTAURANTS,
  "מסעדת": RESTAURANTS,
  "מסעדות": RESTAURANTS,
  "מעסיק": EMPLOYERS,
  "מעסיקים": EMPLOYERS,
  "הטבה": BENEFITS,
  "הטבות": BENEFITS,
  "מוצר": BRANDS,
  "כתבות": BLOGS,
  "מאמר": BLOGS,
  "בלוג": BLOGS,
  "restaurant": RESTAURANTS,
  "recipe": RECIPES,
  "employer": EMPLOYERS
}

export const hasVeganWord = (searchTerm) => {
  const veganWords =
    ["Vegan", "טבעוני", "טבעונית", "טבעוניים", "טבעוניות"];

  for (let word of veganWords) {
    if (searchTerm.includes(word)) return true;
  }

  return false
}

export const getVFEntityByName = (entityName) => {
  switch (entityName) {
    case RESTAURANTS:
      return VF_ENTITIES_TYPE[1];
    case BENEFITS:
      return VF_ENTITIES_TYPE[2];
    case RECIPES:
      return VF_ENTITIES_TYPE[3];
    case BRANCHES:
      return VF_ENTITIES_TYPE[4];
    case BRANDS:
      return VF_ENTITIES_TYPE[5];
    case BLOGS:
      return VF_ENTITIES_TYPE[6];
    case EMPLOYERS:
      return VF_ENTITIES_TYPE[7];
    default: {
      return VF_ENTITIES_TYPE[0];
    }
  }
};

export const countries = [
  {
    name: "israel",
    code: "IL",
    flag: ilFlag,
  },
  {
    name: "united_kingdom",
    code: "GB",
    flag: ukFlag,
  },
  {
    name: "united_states",
    code: "US",
    flag: usFlag,
  },
];

export const serverAuth = {
  identifier: "browser@vegan-friendly.com",
  password: "T6TH5h*y2oZ8",
};

export const googleAPIKey = "AIzaSyAl9QBg0K6p72rI_xPJmEXiE_4-gwwHgqo";

export const VF_COLORS = {
  TEXT: "var(--color-text)",
  MAIN_GREEN: "var(--color-main-green)",
  PAGE: "var(--color-page)",
  LIGHT_GREEN: "var(--color-light-green)",
  SECONDARY_GRAY: "var(--color-secondary-grey)",
  PAGE_NAME: "var(--color-page-name)",
  MAIN_BEET: "var(--color-main-beet)",
  LIGHT_YELLOW: "var(--color-light-yellow)",
  LIGHT_BEET: "var(--color-light-beet)",
  LIGHT_BLUE: "var(--color-light-blue)",
};

export const getCurrentFlag = () => {
  const country = getCurrentCountry();
  if (country === "IL") {
    return ilFlag;
  } else if (country === "GB") {
    return ukFlag;
  } else {
    return usFlag;
  }
};

const getS3 = () => {
  if (process.env.REACT_APP_IMAGE_URL_PREFIX) {
    return process.env.REACT_APP_IMAGE_URL_PREFIX;
  }
  //this is a fallback:
  // console.log("warning - no image-url-prefix configured, falling back to hard-coded URLs");
  const country = getCurrentCountry();
  if (country === "US") {
    return "https://d2jc6q6nnwrv0k.cloudfront.net/";
    // return "https://vf-us-images.s3.us-east-1.amazonaws.com/";
  } else {
    return "https://d1y8eakzhc49p7.cloudfront.net/";
    //return "https://vf-uk-images.s3.eu-central-1.amazonaws.com/";
  }
};

export const showVeganActive = config.currentCountry === "IL";

//TODO: [2024.6.18] rename
export const s3 = getS3();
