import React, { useState, useEffect } from "react";
import {
  Bar,
  BarChart as Chart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Cell,
  Text,
} from "recharts";
import { t } from "i18next";

const getData = (type) => {
  return [
    {
      label: t(`trademarkPage.${type}.section4.survey.columns.first`),
      value: 3.43,
      display: 3.43,
    },
    {
      label: t(`trademarkPage.${type}.section4.survey.columns.second`),
      label: "Self-indicated by the brand as plant-based",
      value: 15.39,
      display: 8.39,
    },
    {
      label: t(`trademarkPage.${type}.section4.survey.columns.third`),
      label: "Self-indicated by the brand as vegan",
      value: 31.61,
      display: 31.61,
    },
    {
      label: t(`trademarkPage.${type}.section4.survey.columns.fourth`),
      value: 55.77,
      display: 55.77,
    },
  ];
};

function CustomizedAxisTick(props) {
  const { x, y, payload, isMobile } = props;

  return (
    <Text
      style={{
        fontWeight: 400,
        fontSize: isMobile ? 12 : 14,
        color: "#596475",
      }}
      x={x}
      y={y}
      width={75}
      textAnchor={isMobile ? "end" : "middle"}
      verticalAnchor={isMobile ? "middle" : "start"}
    >
      {payload.value}
    </Text>
  );
}

function BarChart({ type }) {
  const data = getData(type);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (

    <ResponsiveContainer
      width="100%"
      height={400}
      // minWidth={600}
      style={{ marginTop: "-10%" }}
    >
      <Chart
        layout={isMobile ? "vertical" : "horizontal"}
        data={isMobile ? [...data].reverse() : data}
        margin={{
          top: isMobile ? 75 : 0,
          right: isMobile ? 10 : 0,
          left:  isMobile ? 50 : 50,
          bottom: isMobile ? 20 : 50,
        }}
      >
        {isMobile ? (
          <>
            {/* For vertical layout, use YAxis for categories */}
            <YAxis
              dataKey="label"
              type="category"
              tickLine={false}
              axisLine
              tick={<CustomizedAxisTick isMobile={true} />}
              interval={0}
              stroke="#B9C3CE"
            />
            {/* XAxis for numeric values */}
            <XAxis type="number" hide stroke="#B9C3CE" />
          </>
        ) : (
          <>
            {/* For horizontal layout, use XAxis for categories */}
            <XAxis
              dataKey="label"
              tickLine={false}
              axisLine
              tick={<CustomizedAxisTick />}
              interval={0}
              stroke="#B9C3CE"
            />
            {/* YAxis for numeric values */}
            {/* <YAxis type="number" stroke="#B9C3CE" /> */}
          </>
        )}
        <Bar
          dataKey="value"
          fill="#D35177"
          radius={isMobile ? [0, 15, 15, 0] : [15, 15, 0, 0]}
          minPointSize={50}
          maxBarSize={120}
        >
          {/* Map through each data point and assign a fill color.
              The last bar (index === data.length - 1) will have a different color. */}
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                isMobile
                  ? index !== 0
                    ? "#B9C3CE"
                    : "#D35177"
                  : index === data.length - 1
                  ? "#D35177"
                  : "#B9C3CE"
              }
            />
          ))}
          {/* 
            LabelList lets you render the value or custom text inside the bar.
            position="insideTop" places it near the top inside the bar.
            Use 'formatter' to append '%' to the value.
          */}
          <LabelList
            dataKey="display"
            position={isMobile ? "insideRight" : "insideTop"}
            fill="#fff"
            fontSize={"1.5rem"}
            fontWeight={"600"}
            formatter={(val) => `${val}%`}
            offset={15} // Adjust offset to move label away from bar top edge
          />
        </Bar>
      </Chart>
    </ResponsiveContainer>

  );
}

export default BarChart;
