import entities from "../reducers/entities";
import general from "../reducers/general";
import restaurant from "../reducers/restaurant";
import business from "../reducers/business";
import recipe from "../reducers/recipe";
import user from "../reducers/user";
import company from "../reducers/company";
import blog from "../reducers/blog";
import employer from "../reducers/employer"; 
import certifiedPartners from "../reducers/certifiedPartners";
import { combineReducers, createStore } from "redux";


const store = createStore(
  combineReducers({
    entities,
    general,
    restaurant,
    business,
    recipe,
    user,
    company,
    blog,
    employer,
    certifiedPartners,
  })
);

export default store;
