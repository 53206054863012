import React from "react";
import classes from "./CertifiedEntities.module.css";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import * as links from "../../../../../utils/links";
import images from "../../../../../resources/images";

export default function CertifiedEntities() {
  const navigate = useNavigate();
  const cardsData = [
    {
      image: images.trademarks.chips,
      title: t("trademarkPage.general.howTo.products.title"),
      subtitle: t("trademarkPage.general.howTo.products.description"),
      cta: {
        label: "",
        action: () => {
          navigate(links.linkToTrademark("product"));
        },
      },
    },
    {
      image: images.trademarks.table,
      title: t("trademarkPage.general.howTo.restaurant.title"),
      subtitle: t("trademarkPage.general.howTo.restaurant.description"),
      cta: {
        label: "",
        action: () => {
          navigate(links.linkToTrademark("business"));
        },
      },
    },
    {
      image: images.trademarks.building,
      title: t("trademarkPage.general.howTo.business.title"),
      subtitle: t("trademarkPage.general.howTo.business.description"),
      cta: {
        label: "",
        action: () => {
          navigate(links.linkToTrademark("business"));
        },
      },
    },
  ];
  return (
    <div className={classes.containerWrapper}>
      <div className={classes.container}>
        <p className={classes.title}>{t("trademarkPage.general.howTo.title")}</p>
        {cardsData.map((card) => (
          <div key="card-container" className={classes.cardContainer}>
            <div key="image-container" className={classes.imageContainer}>
              <div key={"image"} className={classes.image}>
                <img
                  src={card.image}
                  style={{ width: '100px', height: '100px', objectFit: 'contain' }}
                  />
              </div>
            </div>
            <div key={"content-container"} className={classes.cardContent}>
              <p className={classes.cardTitle}>{card.title}</p>
              <p className={classes.cardSubtitle}>{card.subtitle}</p>
              <button className={classes.cardButton} onClick={card.cta.action}>
                {t("trademarkPage.general.howTo.learn")}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
