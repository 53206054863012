import React from "react";
import CtaButton from "./certificationCta/CertificationButton";
import styles from "./CtaButtonsContainder.module.css";
import { ReactComponent as CertificationSVG } from '../../../../assets/images/certificationSVG.svg';
export default function CtaButtonsContainer({ scrollToSection }) {
  const buttons = [
    { title: "Get Certified",icon:true, refName: "certificationRef" },
    { title: "Certification Criteria", refName: "criteriaRef" },
    { title: "FAQs", refName: "faqRef" },
  ];
  return (
    <div className={styles.container}>
      {buttons.map((button) => (
        <CtaButton
          title={button.title}
          handleClick={scrollToSection}
          scrollTo={button.refName}
          icon={button.icon}
        />
      ))}
    </div>
  );
}
