import React, { useEffect, useRef, useState } from "react";
import images from "../../../../resources/images";
import BarChart from "./bar-chart/BarChart";
import styles from "./SurveyResults.module.css";
import { t } from "i18next";
function SurveyResults({ type }) {
  const highlightRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      {
        root: null, // Observes viewport
        threshold: 0.3, // 30% of the element must be visible
        rootMargin: "-30% 0px -30% 0px", // Adjust to trigger roughly in the middle
      }
    );

    if (highlightRef.current) {
      observer.observe(highlightRef.current);
    }

    return () => {
      if (highlightRef.current) {
        observer.unobserve(highlightRef.current);
      }
    };
  }, []);
  return (
    <section className={styles.container}>
      <div className={styles.containerWrapper}>
        <div className={styles.contentWrapper}>
          <div className={styles.mainSection}>
            <div className={styles.imageColumn}>
              <div className={styles.imageContainer}>
                <img
                  loading="lazy"
                  src={
                    type === "business"
                      ? images.businessSurvey
                      : "https://cdn.builder.io/api/v1/image/assets/c759f4095b3644919970c2fb0a0256f9/ba2e09715fd42143a7987333055004ab06a5cfe575f46609850ae8ccac15c85c?apiKey=c759f4095b3644919970c2fb0a0256f9&"
                  }
                  className={styles.surveyImage}
                  alt="Survey results visualization"
                />
                <div>
                  <p className={styles.imageCaption}>
                    {t(`trademarkPage.${type}.section4.survey.title`)}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.contentColumn}>
              <div className={styles.textContent}>
                <h1 className={styles.title}>
                  {t(`trademarkPage.${type}.section4.title`)}
                </h1>
                <h2 className={styles.subtitle}>
                  {t(`trademarkPage.${type}.section4.subtitle`)}
                </h2>
                <p className={styles.description}>
                  {t(`trademarkPage.${type}.section4.body`)}
                </p>
              </div>
              <div
                ref={highlightRef}
                className={`${styles.highlightContainer} ${
                  isVisible ? styles.animated : ""
                }`}
              >
                <div className={styles.highlightBox}>
                  {t(`trademarkPage.${type}.section4.survey.bubble`)}
                </div>
                <div className={styles.highlightArrow}></div>
              </div>
            </div>
          </div>
        </div>

        <section className={styles.statsContainer}>
          <div className={styles.bottomLeaf}>
            <img src={images.leaf} className={styles.img} />
          </div>
          <div className={styles.topLeaf}>
            <img src={images.leaf} className={styles.img} />
          </div>
          <div className={styles.statsGrid}>
            <div className={styles.statsColumn}>
              <div className={styles.statsHeader}>
                <div style={{ paddingTop: "5%" }}>
                  <h2 className={styles.statsTitle}>
                    {t(`trademarkPage.${type}.section4.survey.main`)}
                  </h2>
                  <p className={styles.statsSubtitle}>
                    {t(`trademarkPage.${type}.section4.survey.secondary`)}
                  </p>
                </div>
                  <BarChart type={type} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
export default SurveyResults;
