import classes from "./PartnerItem.module.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import images from "../../../resources/images";
import icons from "../../../resources/icons";
import React from "react";

const PartnerItem = ({ data, style, isLoading = true }) => {
  const company = data;
  const bizType = company.biz_type;

  const getEntityTypeIcon = React.useCallback(
    (bizType) => {
      switch (bizType) {
        case "Product":
          return icons.productsEntity;
        case "Restaurant":
          return icons.restaurantsEntity;
        case "Business":
          return icons.businessesEntity;

        default:
          return icons.productsEntity;
      }
    },
    [bizType]
  );
  return (
    <div className={`${style} ${classes.mainContainer} `}>
      <div className={classes.imageContainer}>
        {isLoading ? (
          <Skeleton
            height="100%"
            style={{
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
            }}
          />
        ) : (
          <img
            className={`${classes.image} ${
              company.disableClick ? classes.noCursor : ""
            }`}
            src={company.logo ? company.logo : images.defaultEntityLogo}
            alt="company logo"
          />
        )}
      </div>
      <div
        className={`${classes.contentContainer} ${
          company.disableClick ? classes.noCursor : ""
        }`}
      >
        <p className={classes.companyTitle}>
          {isLoading ? (
            <Skeleton borderRadius="20px" width="80%" />
          ) : (
            company.name
          )}
        </p>
        <div className={classes.entityType}>
          <img className={classes.entityTypeIcon} src={getEntityTypeIcon()} />
          <p className={classes.entityTypeTitle}>{company.biz_type}</p>
        </div>
        {company.subCategories && (
          <div className={classes.tagContainer}>
            {company.subCategories.map((category, index, row) => (
              <p className={classes.tag} key={category.category_name}>
                {category.category_name}
                {index !== row.length - 1 && index % 2 === 0 && (
                  <span
                    style={{
                      fontSize: ".8rem",
                      height: "2rem",
                      lineHeight: "1.4rem",
                      marginInlineStart: "0.5rem",
                    }}
                  >
                    •
                  </span>
                )}
              </p>
            ))}
          </div>
        )}
        <p className={classes.companyDescription} style={{ height: "8rem" }}>
          {company.description}
        </p>
      </div>
    </div>
  );
};

export default PartnerItem;
