import React from "react";
import ProductCard from "./components/ProductCard";
import { productCards, businessCards } from "./data/productCards";
import styles from "./styles/ProductTypes.module.css";
import { t } from "i18next";

export function ProductTypes({ type }) {
  const cards = type === "product" ? productCards : businessCards;

  return (
    <section className={styles.container}>
      <h1 className={styles.heading}>
        {t(`trademarkPage.${type}.section3.title`)}
      </h1>
      <p className={styles.description}>
        {t(`trademarkPage.${type}.section3.subtitle`)}
      </p>
      <h2 className={styles.subheading}>
        {t(`trademarkPage.${type}.section3.example`)}
      </h2>

      <div className={styles.mainGrid}>
        <div className={styles.cardGrid}>
          {cards
            .filter((card) =>
              card.countries.includes(process.env.REACT_APP_COUNTRY)
            )
            .map((card) => (
              <ProductCard
                key={card.id}
                image={card.image}
                title={card.title}
                type={card.type}
                entityType={type}
              />
            ))}
          {((type === "product" ||
            cards.filter((card) =>
              card.countries.includes(process.env.REACT_APP_COUNTRY)
            ).length < 8) && (
              <p className={styles.moreText}>
                {t(`trademarkPage.${type}.section3.types.more`)}
              </p>
            ))}
        </div>
      </div>
    </section>
  );
}
