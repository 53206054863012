import { t } from "i18next";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logEvents } from "../../../eventsManager";
import {
  BENEFITS,
  BLOGS,
  BRANCHES,
  BRANDS,
  EMPLOYERS,
  RECIPES,
  RESTAURANTS,
} from "../../../resources/config";
import icons, { iconsAlts } from "../../../resources/icons";
import * as links from "../../../utils/links";
import { getCurrentCountry } from "../../../utils/utilFunctions";
import classes from "../EntitiesMenu.js/EntitiesMenu.module.css";
export default function GetTrademarkMenu({ onClose }) {
  const logClickEvent = (buttonName) => {
    onClose();
    const params = { button_name: buttonName };
    logEvents("header_and_footer_click", params);
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.itemsContainer}>
        <Link
          className={classes.menuItem}
          to={links.linkToCertifiedPartners()}
          onClick={() => logClickEvent("certified_partners")}
        >
          <img
            className={classes.icon}
            src={icons.partnersButton}
            alt={iconsAlts.companiesButton}
            style={{ width: "42px", height: "42px" }}
            resizeMode={"contain"}
          />
          <p className={classes.itemTitle}>{t("partners")}</p>
        </Link>
        <Link
          className={classes.menuItem}
          to={links.linkToTrademark("product")}
          onClick={() => logClickEvent("trademark_product")}
        >
          <img
            className={classes.icon}
            src={icons.companiesButton}
            alt={iconsAlts.companiesButton}
          />
          <p className={classes.itemTitle}>{t("companies")}</p>
        </Link>
        <Link
          className={classes.menuItem}
          to={links.linkToTrademark("business")}
          onClick={() => logClickEvent("trademark_business")}
        >
          <img
            className={classes.icon}
            src={icons.businessesButton}
            alt={iconsAlts.businessesButton}
          />
          <p className={classes.itemTitle}>{t("businesses")}</p>
        </Link>
      </div>
    </div>
  );
}
