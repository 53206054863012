import React, { useEffect, useState } from "react";
import styles from "./FlowChart.module.css";
import { t } from "i18next";
import images from "../../../../resources/images";
import icons from "../../../../resources/icons";
import CardItem from "./CardItem";

const getTimelineData = (type, scrollToSection) => {
  return [
    {
      icon: icons.list,
      title: t(`trademarkPage.${type}.section8.items.0.title`),
      first_body: t(`trademarkPage.${type}.section8.items.0.first_body`),
      second_body: t(`trademarkPage.${type}.section8.items.0.second_body`),
      action: t(`trademarkPage.${type}.section8.items.0.action`),
      onPress: () => {
        scrollToSection("certificationRef");
      },
    },
    {
      icon: icons.phone,
      title: t(`trademarkPage.${type}.section8.items.1.title`),
      description: t(`trademarkPage.${type}.section8.items.1.body`),
    },
    {
      icon: icons.trophy,
      title: t(`trademarkPage.${type}.section8.items.2.title`),
      description: t(`trademarkPage.${type}.section8.items.2.body`),
    },
    {
      title: t(`trademarkPage.${type}.section8.items.3.title`),
      description: t(`trademarkPage.${type}.section8.items.3.body`),
    },
  ];
};

export default function FlowChart({ type, scrollToSection }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const timelineData = getTimelineData(type, scrollToSection);
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <p className={styles.sectionTitle}>
          {t(`trademarkPage.${type}.section8.title`)}
        </p>
        <p className={styles.sectionSubtitle}>
          {t(`trademarkPage.${type}.section8.subtitle`)}
        </p>
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          gap: "2rem",
          alignItems: isMobile ? "center" : undefined,
        }}
      >
        {timelineData.map((item, index) => (
          <CardItem item={item} index={index} isMobile={isMobile} />
        ))}
      </div>
    </div>
  );
}
