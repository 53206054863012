import React, { useState } from "react";
import { motion } from "framer-motion";
import styles from "../styles/ProductCard.module.css";
import { t } from "i18next";

export default function ProductCard({ image, title, type, entityType }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={styles.card}
      tabIndex="0"
      role="article"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={styles.cardContent}>
        <div className={styles.cardImageGradient}>
          </div>
          <img
            loading="lazy"
            src={image}
            alt={`Product category: ${title}`}
            className={styles.cardImage}
          />
        <motion.div
          initial={{ y: 0 }}
          animate={{ y: isHovered ? "100%" : "0%" }}
          transition={{ duration: 0.1, ease: "easeInOut" }}
          className={styles.cardOverlay}
        />
        <motion.p
          animate={{
            color: isHovered ? "#ffffff" : "#596475",
            fontWeight: isHovered ? 700 : 400,
          }}
          transition={{ duration: 0.1, ease: "easeInOut" }}
          className={styles.cardTitle}
        >
          {t(`trademarkPage.${entityType}.section3.types.${type}`)}
        </motion.p>
      </div>
    </div>
  );
}
