import React, { useCallback, useState } from "react";
import styles from "./CertificationForm.module.css";
import api from "../../../../api";
import PageError from "../../../PageError/PageError";
import Loader from "../../../../components/Loader/Loader";
import { PrimaryButton } from "../../../../components/Common";
import images, { imagesAlts } from "../../../../resources/images";
//TODO: move this to a shared place? or use React 19's useFormStatus()
const FormState = Object.freeze({
  IDLE: "IDLE",
  SUBMITTING: "SUBMITTING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
});

const formFields = [
  { id: "companyName", label: "Company Name", required: true },
  { id: "fullName", label: "Your First & Last Name", required: true },
  { id: "email", label: "Email Address", required: true, type: "email" },
  { id: "position", label: "Your Position", required: false },
  { id: "website", label: "Website Link", required: true, type: "text" },
  { id: "phone", label: "Phone Number", required: false, type: "tel" },
];

const CertificationForm = React.forwardRef((props, ref) => {
  //TODO: replace with useFormStatus() when migrating to React 19
  const [formState, setFormState] = useState(FormState.IDLE);
  const [formData, setFormData] = useState(initialFormData(formFields));

  const source = props.source;

  const handleChange = useCallback((e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        setFormState(FormState.SUBMITTING);

        const response = await api.submitGetCertifiedForm({
          ...formData,
          source,
        });
        console.log("response:", response);

        if (response?.success) {
          setFormState(FormState.SUCCESS);
          console.log("setFormData(initialFormData(formFields));");
          setFormData(initialFormData(formFields));
        } else {
          setFormState(FormState.ERROR);
        }
      } catch (error) {
        console.error("getCertifiedPartners error:", error);
        setFormState(FormState.ERROR);
      }
    },
    [formData, source]
  );

  return (
    <div className={styles.mainContainer} ref={ref}>
      <section className={styles.formWrapper}>
        {formState === FormState.SUBMITTING && (
          <div className={styles.loaderModal}>
            <Loader />
          </div>
        )}
        {formState === FormState.ERROR && (
          <div className={styles.errorModal}>
            <div className={styles.errorContent}>
              <PageError onTryAgain={handleSubmit} />
            </div>
          </div>
        )}
        {formState === FormState.SUCCESS && (
          <div className={styles.loaderModal}>
            <div className={styles.submitContainer}>
              <img
                className={styles.submitImage}
                src={images.submitCat}
                alt={imagesAlts.error500}
              />
              <p className={styles.submitTitle}>Thanks for reaching out!</p>
              <p className={styles.submitText}>We'll be in contact soon!</p>
              <PrimaryButton
                title={"Close"}
                isSlim={true}
                onClick={() => {
                  setFormState(FormState.IDLE);
                }}
              />
            </div>
          </div>
        )}
        <h1 className={styles.formTitle}>Get Certified With Us!</h1>
        <p className={styles.formDescription}>
          Fill out your details, and our team will reach out to guide you
          through the certification process
        </p>

        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formFields}>
            {formFields.map((field) => (
              <div className={styles.inputWrapper}>
                <input
                  key={field.id}
                  id={field.id}
                  name={field.id}
                  label={field.label}
                  required={field.required}
                  aria-required={field.required}
                  type={field.type || "text"}
                  value={formData[field.id]}
                  onChange={handleChange}
                  disabled={formState === FormState.SUBMITTING}
                  className={styles.inputField}
                  placeholder={field.label + `${field.required ? "*" : ""}`}
                  aria-label={field.label}
                />
              </div>
            ))}
          </div>

          <div className={styles.textareaWrapper}>
            <textarea
              placeholder="Anything you'd like us to know?"
              id="additionalInfo"
              maxLength={2000}
              className={styles.textarea}
              aria-label="Additional Information"
              value={formData.additionalInfo}
              onChange={handleChange}
              disabled={formState === FormState.SUBMITTING}
            />
            <div className={styles.textareaLimitContainer}>
              <p className={styles.textareaLimitTitle}>
                {formData.additionalInfo?.length}/2000
              </p>
            </div>
          </div>

          <button
            type="submit"
            className={styles.submitButton}
            disabled={formState === FormState.SUBMITTING}
          >
            Submit
          </button>
        </form>
      </section>
    </div>
  );
});
export default CertificationForm;

function initialFormData(formFields) {
  return formFields.reduce(
    (acc, field) => {
      acc[field.id] = "";
      return acc;
    },
    {
      additionalInfo: "",
    }
  );
}
