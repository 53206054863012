import React, { useEffect, useState } from "react";
import FooterList from "./FooterList";
import logo from "../../assets/images/logo.png";
import classes from "./VfFooter.module.css";
import {
  ContactUsModal,
  ContactUsSuccessModal,
  NewsletterModal,
  NewsModal,
} from "../../modals";
import * as footerLists from "./footerItemsConfig";
import FooterButtons from "./FooterButtons";
import config from "../../config";
import { isMobile } from "react-device-detect";
import { logEvents } from "../../eventsManager";
import * as links from "../../utils/links";
import { AppDownloadBanner, BusinessBanner } from "../../components/Banners";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const VfFooter = () => {
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const [contactUsOption, setContactUsOption] = useState(null);
  const [isContactUsSuccessOpen, setContactUsSuccessOpen] = useState(false);
  const [contactUsRequestCode, setContactUsRequestCode] = useState(null);
  const [isNewsletterModalVisible, setNewsletterModalVisible] = useState(false);
  const [isNewsModalOpen, setNewsModalOpen] = useState(false);
  const navigate = useNavigate();

  const isUS = config.currentCountry === "US";
  const isIL = config.currentCountry === "IL";

  const onCertifiedClick = () => {
    if (config.currentCountry === "US") {
      if (!isNewsModalOpen) {
        setNewsModalOpen(true);
      }
    } else if (config.currentCountry === "IL") {
      links.linkToExternalPage(links.URLS.get_certified);
    } else {
      navigate(links.linkToTrademark("general", true));
    }
  };

  const contactUsHandler = () => {
    setContactModalOpen(true);
    logEvents("header_and_footer_click", { button_name: "contact_us" });
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.contentContainer}>
        <div className={classes.bannerContainer}>
          {isIL ? (
            <AppDownloadBanner />
          ) : (
            <BusinessBanner isMobile={true} isGreen={true} />
          )}
          {!isMobile && (
            <div className={classes.versionContainer}>
              <h6 className={classes.rightsText}>{t("rights")}</h6>
              {isIL && (
                <h6 className={classes.rightsText}>
                  האתר נבנה בתמיכת המשרד להגנת הסביבה
                </h6>
              )}

              <p className={classes.versionTag}>version : {config.version}</p>
            </div>
          )}
        </div>

        <FooterButtons
          className={classes.buttonsContainer}
          contactUsHandler={contactUsHandler}
        />
        <div className={classes.listContainer}>
          <FooterList
            list={footerLists.getOurBusinessListByCountry()}
            certifiedHandler={onCertifiedClick}
            title="our_businesses"
          />
          <FooterList
            list={footerLists.getInvolvedListByCountry()}
            title="get_involved"
            contactUsHandler={() => {
              setContactUsOption(1);
            }}
            newsLetterHandler={() => {
              setNewsletterModalVisible(true);
            }}
          />
          <FooterList isMedia={true} title="follow_us" />
          <FooterList
            list={footerLists.getLegalListByCountry()}
            title="legal"
          />
        </div>

        {isMobile && (
          <div className={classes.versionContainer}>
            <p className={classes.rightsText}>{t("rights")}</p>
            <p className={classes.versionTag}>V {config.version}</p>
          </div>
        )}

        <div className={classes.logoContainer}>
          <img
            src={logo}
            className={classes.logo}
            alt={"Vegan Friendly logo"}
          />
        </div>
      </div>
      <ContactUsModal
        show={isContactModalOpen || contactUsOption != null}
        onHide={() => {
          setContactUsOption(null);
          setContactModalOpen(false);
        }}
        optionNumber={contactUsOption}
        onSuccess={(code) => {
          setContactUsSuccessOpen(true);
          setContactUsRequestCode(code);
        }}
      />
      <ContactUsSuccessModal
        show={isContactUsSuccessOpen}
        onHide={() => {
          setContactUsSuccessOpen(false);
        }}
        caseNumber={contactUsRequestCode}
      />
      <NewsletterModal
        show={isNewsletterModalVisible}
        onHide={() => setNewsletterModalVisible(false)}
      />
      <NewsModal
        show={isNewsModalOpen}
        onHide={() => setNewsModalOpen(false)}
        newsId={1}
      />
    </div>
  );
};
export default VfFooter;
