import * as React from "react";
import styles from "./CertificationButton.module.css";
import { ReactComponent as CertificationSVG } from "../../../../../assets/images/certificationSVG.svg";

export default function CtaButton({ handleClick, icon, title, scrollTo }) {
  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      handleClick(scrollTo);
    }
  };

  return (
    <div className={styles.certificationButton}>
      <div
        className={styles.buttonWrapper}
        role="button"
        tabIndex="0"
        onClick={() => handleClick(scrollTo)}
        onKeyDown={handleKeyPress}
        aria-label="Get Certified"
      >
        {icon && <CertificationSVG className={styles.certificationIcon} />}

        <p className={styles.buttonText}>{title}</p>
      </div>
    </div>
  );
}
