import { SET_CERTIFIED_PARTNERS } from "../actions/certifiedPartnersActions";

const defaultState = {
  data: null,
  success: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_CERTIFIED_PARTNERS:
      return action.payload;
  }
  return state;
};
